import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/assets/variables.scss'],
  lang: {
    locales: { en, es },
    current: 'en',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      // light: {
      //   primary: '#123a5f',
      //   background: '#F2F2F2',
      //   secondary: '#424242',
      //   accent: '#82B1FF',
      //   error: '#FF5252',
      //   info: '#2196F3',
      //   success: '#4CAF50',
      //   warning: '#FFC107',
      // },
      // dark: {
      //   accent: '#2196F3'
      // },
      light: {
        primary: process.env.VUE_APP_THEME_PRIMARY == null ? '#123a5f' : process.env.VUE_APP_THEME_PRIMARY,
        background: process.env.VUE_APP_THEME_BACKGROUND == null ? '#F2F2F2' : process.env.VUE_APP_THEME_BACKGROUND,
        secondary: process.env.VUE_APP_THEME_SECONDARY == null ? '#424242' : process.env.VUE_APP_THEME_SECONDARY,
        accent: process.env.VUE_APP_THEME_ACCENT == null ? '#123a5f' : process.env.VUE_APP_THEME_ACCENT,
        error: process.env.VUE_APP_THEME_ERROR == null ? '#FF5252' : process.env.VUE_APP_THEME_ERROR,
        info: process.env.VUE_APP_THEME_INFO == null ? '#2196F3' : process.env.VUE_APP_THEME_INFO,
        success: process.env.VUE_APP_THEME_SUCCESS == null ? '#4CAF50' : process.env.VUE_APP_THEME_SUCCESS,
        warning: process.env.VUE_APP_THEME_WARNING == null ? '#FFC107' : process.env.VUE_APP_THEME_WARNING,
      },
      dark: {
        accent: '#2196F3',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});
