<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    
    <v-navigation-drawer app :mini-variant="!$vuetify.breakpoint.mobile" v-if="loggedIn" :clipped="true" :expand-on-hover="!$vuetify.breakpoint.mobile" v-model="drawer" :permanent="!$vuetify.breakpoint.mobile" :class="{ 'primary': theme != 'dark' }" dark>
      <v-list-item class="px-2">
        <!-- <v-avatar :tile="true" v-if="$store.getters.LOGO != null" class="mr-3">
          <v-img :src="$store.getters.LOGO" alt="logo" contain></v-img>
        </v-avatar> -->
        <v-list-item-avatar>
          <v-icon>mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense :key="rerendernav" v-if="$store.getters.UPDATINGSUB != true">
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          link
          :to="getItemTo(item.to, item.upgradeAvailable)"
          :disabled="(item.status === true && !status)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="item.upgradeAvailable && status">
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-chip small color="green" v-bind="attrs" v-on="on" @click="$router.push('payments')">{{ $t('upgrade') }}</v-chip>
              </template>
              <span class="text-center">{{ $t('upgradeSubPlan') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list dense :key="rerendernav" v-if="$vuetify.breakpoint.mobile">
          <v-list-item link to="/myaccount">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('myAccount.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="dark = !dark">
            <v-list-item-icon>
              <v-icon>mdi-brightness-6</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('themeChange') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="pa-2">
          <v-theme-provider root>
            <v-select v-model="language" :items="languages" item-text="title" item-value="language" hide-details single-line solo></v-select>
          </v-theme-provider>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app fixed :clipped-left="true" :elevate-on-scroll="!drawer" scroll-target="#scrollTarget" :class="{ 'primary': theme != 'dark' }" :dark="theme != 'dark'" v-if="showBar">
      <v-app-bar-nav-icon v-if="loggedIn && $vuetify.breakpoint.mobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-avatar size="80" :tile="true" v-if="$store.getters.LOGO != null" class="mr-3">
        <v-img :src="$store.getters.LOGO" alt="logo" contain></v-img>
      </v-avatar>
      <v-toolbar-title hover @click="redirect()" class="font-weight-black headline">
        <div v-if="orgName != null">
          <span v-if="$store.getters.LOGO == null">{{ orgName }} </span>
          <span class="text-caption">powered by 
            <span v-if="appLogo == null" class="text-subtitle-1 font-weight-black">{{ appTitle }}</span>
            <span v-else>
              <img style="vertical-align: middle" class="mb-1" :src="appLogo" height="23"/>
            </span>
          </span> 
        </div>
        <div v-else>
          <span v-if="appLogo == null">{{ appTitle}}</span>
          <span v-else>
            <img style="vertical-align: middle" class="mr-3" :src="appLogo" height="25"/>
          </span>
        </div>
      </v-toolbar-title>
      <v-icon v-if="!['Home', 'Login', 'SignUp', 'ThankYou', 'Auth', 'Public'].includes($route.name) && !$vuetify.breakpoint.mobile" class="ml-8" @click="$router.replace({name:'Home'})">mdi-home</v-icon>
      <div class="flex-grow-1"></div>
      <v-btn v-if="!['Home', 'Login', 'SignUp', 'ThankYou', 'Auth', 'Public'].includes($route.name) && !$vuetify.breakpoint.mobile && whiteLabel == null" dense text target="_blank" href="https://contractflows.com/docs">{{ $t('goToDocs') }}</v-btn>
      <v-tooltip bottom  v-if="loggedIn && !$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on" icon v-if="loggedIn" to="/myaccount">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('myAccount.title') }}</span>
      </v-tooltip>
      
      <v-menu offset-y v-if="loggedIn">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="logout"
          >
            <v-list-item-title>{{ $t('logOut') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="!$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs"  v-on="on" icon @click="dark = !dark">
            <v-icon>mdi-brightness-6</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('themeChange') }}</span>
      </v-tooltip>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    
    <v-main id="scrollTarget">
      <v-alert v-if="status === false && roles !== null && $route.name != 'payments'" prominent dense type="info" class="mb-0">
        <v-row align="center">
          <v-col v-if="roles != null && roles.includes('owner')" class="grow">{{ $t('attentionRequired') }}</v-col>
          <v-col v-else class="grow">{{ $t('attentionRequired2') }}</v-col>
          <v-col v-if="roles.includes('owner') && whiteLabel==null" class="shrink">
            <v-btn color="accent" to="payments">{{ $t('goToPayments') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert v-if="expiredSubscription === true && roles !== null && $route.name != 'payments'" prominent dense type="warning" class="mb-0">
        <v-row align="center">
          <v-col class="grow">{{ $t('expiredSubscription') }}<span v-if="whiteLabel == null">: support@contractflows.com</span>
          </v-col>
        </v-row>
      </v-alert>
      <v-dialog persistent v-model="incompleteUser" max-width="550" v-if="loggedIn && !['Shared', 'Login', 'SignUp', 'ThankYou', 'Auth', 'DS', 'ThankYouSignUp'].includes($route.name)">
        <infoForm></infoForm>
      </v-dialog>
      <!-- <div style="min-height: 100vh"> -->
      <!-- <div class="text-right"> {{ drawer }}</div>  -->
      <router-view ></router-view>
    </v-main>

    <!-- <v-footer app absolute padless>
      <v-card text tile class="lighten-1 white--text text-center" width=100%>
        <v-card-text class="pb-0">
          <v-row justify="center">
            <v-col class="text-right py-0">
              <a href="https://contractflows.com/terms" target="_blank" depressed class="white--text d-inline-flex px-5 pa-md-0">
                Terms and conditions
              </a>
            </v-col>
            <v-col class="text-left py-0">
              <a href="https://contractflows.com/privacy" target="_blank" depressed class="white--text px-5 pa-md-0">
                Privacy policy
              </a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-2">
          {{ new Date().getFullYear() }} — <strong>Contractflows</strong>
        </v-card-text>
      </v-card>
    </v-footer> -->
    <global-snackbar />
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import firebase from 'firebase'
import i18n from '@/plugins/i18n'
import infoForm from '@/components/incompleteUser/infoForm'
import GlobalSnackbar from '@/components/common/GlobalSnackbar.vue'

export default {
  name: 'App',
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: process.env.VUE_APP_DESCRIPTION },
    ]
  },
  components: {
    infoForm,
    GlobalSnackbar
  },
  data: () => ({
    incompleteUser: null,
    loggedIn: null,
    roles: null,
    email: null,
    logoutDialog: false,
    
    drawer: false,
    
    permissions: null,
    rerendernav: 0,
    navItems2: [],
    status: null,
    expiredSubscription: null,
    language: 'en',
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'es', language: 'es', title: 'Español' }
    ],
    dark: false,
    backupTheme: {
      primary: process.env.VUE_APP_THEME_PRIMARY == null ? '#123a5f' : process.env.VUE_APP_THEME_PRIMARY,
      background: process.env.VUE_APP_THEME_BACKGROUND == null ? '#F2F2F2' : process.env.VUE_APP_THEME_BACKGROUND,
      secondary: process.env.VUE_APP_THEME_SECONDARY == null ? '#424242' : process.env.VUE_APP_THEME_SECONDARY,
      accent: process.env.VUE_APP_THEME_ACCENT == null ? '#123a5f' : process.env.VUE_APP_THEME_ACCENT,
      error: process.env.VUE_APP_THEME_ERROR == null ? '#FF5252' : process.env.VUE_APP_THEME_ERROR,
      info: process.env.VUE_APP_THEME_INFO == null ? '#2196F3' : process.env.VUE_APP_THEME_INFO,
      success: process.env.VUE_APP_THEME_SUCCESS == null ? '#4CAF50' : process.env.VUE_APP_THEME_SUCCESS,
      warning: process.env.VUE_APP_THEME_WARNING == null ? '#FFC107' : process.env.VUE_APP_THEME_WARNING,
    },
    btnRadius: process.env.VUE_APP_BTN_RADIUS == null ? '4px' : process.env.VUE_APP_BTN_RADIUS
  }),
  computed: {
    appLogo() {
      return process.env.VUE_APP_DEFAULT_LOGO
    },
    navItemsOptions(){
      var a = [
        { title: 'processes', icon: 'mdi-file-document-box-multiple', to:'/processes', roles:['owner', 'creator', 'consultant'], status: true},
        { title: 'review', icon: 'mdi-message-draw', to:'/reviews', roles:['owner', 'reviewer'], status: true, permissions:['reviewProcess']},
        { title: 'organization', icon: 'mdi-domain', to:'/manageorg', roles:['owner', 'manager'], status: false},
        { title: 'templates', icon: 'mdi-file-document-edit', to:'/managetemplates', roles:['owner', 'manager'], status: true, permissions:['customTemplates']}
      ]
      if(process.env.VUE_APP_DISPLAY_PAYMENTS != 'false'){
        a.push({ title: 'plans', icon: 'mdi-credit-card-outline', to:'/payments', roles:['owner'], status: false})
      }
      a.push({ title: 'dashboardT', icon: 'mdi-chart-line', to:'/dashboard', roles:['owner', 'manager'], status: true, permissions: ['dashboard']})
      a.push({ title: 'alerts.title', icon: 'mdi-bell', to:'/alerts', roles:['owner', 'creator', 'reviewer', 'manager'], status: true, permissions: ['alerts']})
      if(process.env.VUE_APP_DISPLAY_UTILITIES != 'false'){
        a.push({ title: 'utilities', icon: 'mdi-hammer-screwdriver', to:'/utilities', roles:['owner', 'creator', 'reviewer', 'manager'], status: true, permissions: ['utilities']})
      }
      // a.push({ title: 'repository.title', icon: 'mdi-folder-multiple', to:'/repository', roles:['owner', 'creator', 'reviewer', 'manager'], status: true, permissions: ['repository']})
      return a
    },
    whiteLabel() {
      return process.env.VUE_APP_WHITE_LABEL
    },
    appTitle() {
      return process.env.VUE_APP_TITLE
    },
    orgName () {
      return this.$store.state.orgName
    },
    showBar() {
      return !['Login', 'SignUp', 'FreeTrial'].includes(this.$route.name) || this.$vuetify.breakpoint.smAndDown
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    currentRouteName() {
        return this.$route.name;
    },
    navItems () {
      if(!this.loggedIn) return []
      var items = []
      items.push({ title: 'home', icon: 'mdi-home', to: '/home', roles:['owner', 'manager', 'creator', 'reviewer'], status: false})
      if(this.$store.getters.ROLES != null){
        for(var idx in this.navItemsOptions){
          var obj = this.navItemsOptions[idx]
          const found = this.$store.getters.ROLES.some(r=> obj['roles'].includes(r))
          if(found){
            if(this.status && this.permissions != null && obj.permissions != null){
              obj.permissions.forEach(ele =>{
                if(!this.permissions[ele]){
                  obj.upgradeAvailable = true
                }
                else{
                  obj.upgradeAvailable = false
                }
              })
            }
            items.push(obj)
          }
        }
      }
      
      return items
    }
  },
  methods: {
    getItemTo(to, upgrade){
      if(upgrade === true){
        return ''
      }
      else return to
    },
    redirect() {
      if(!this.loggedIn){
        // window.location.href = 'https://contractflows.com'
      }
      
    },
    logout () {
      this.$vuetify.theme.dark = false
      for(var key in this.backupTheme){
        this.$vuetify.theme.themes['light'][key] = this.backupTheme[key]
      }

      this.$store.dispatch('RESET_EVERYTHING').then(() => {
        firebase.auth().signOut().then(() => {
          this.$router.push({name: 'Login'})
          this.loggedIn = false
        })
      })
    },
    getBasicInfo() {
      let vm = this
      vm.$store.dispatch('SAVE_TOKEN').then(() => {
        vm.$store.dispatch('GET_BASIC_INFO').then(() => {
        })
      })
      
    },
    refreshToken() {
      this.$store.dispatch('SAVE_TOKEN').then(() =>{
        this.$store.dispatch('GET_BASIC_INFO').then(() => {
        })
      })
    },
    checkAuth () {
      let vm = this
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          var emailVerified = user.emailVerified
          if (!emailVerified) {
            vm.loggedIn = true
            vm.$store.dispatch('SAVE_VERIFIED', false)
            user.sendEmailVerification().then(function() {
            // Email sent.
            // eslint-disable-next-line
            }).catch(function(error) {
            // An error happened.
            });
          } else {
            // vm.createNavItems()
            vm.loggedIn = true
            vm.getBasicInfo()
            setInterval(function() {
                vm.refreshToken()
            }, 40*60*1000) //      1000 milliseconds = 1 second   60 seconds = 1 minute 
            vm.loggedIn = true
            vm.email = user.email
            
          }
        } else {
          // vm.navItems=[]
          vm.loggedIn = false
        }
      })
    },
    back () {
      this.$router.go(-1)
    },
    home () {
      this.$router.replace('/home')
    },
    goToMyAcc () {
      this.$router.replace('/myaccount')
    },
    changeLocale (locale) {
      i18n.locale = locale
    },
    getBrowserLocale(options = {}) {
      const defaultOptions = { countryCodeOnly: false }
      const opt = { ...defaultOptions, ...options }
      const navigatorLocale =
        navigator.languages !== undefined
          ? navigator.languages[0]
          : navigator.language
      if (!navigatorLocale) {
        return undefined
      }
      const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim()
      return trimmedLocale
    }
  },
  watch: {
    loggedIn: function () {
      if(this.loggedIn){
        const recaptcha = this.$recaptchaInstance
        try {
          recaptcha.hideBadge()
        }
        // eslint-disable-next-line
        catch {
        }
      }
    },
    language: function () {
      localStorage.language = this.language
      this.$i18n.locale = this.language
      this.$vuetify.lang.current = this.language
      this.$store.dispatch('UPDATE_USERS_ROLES')
    },
    dark: function() {
      let vm = this
      localStorage.dark = vm.dark
      vm.$vuetify.theme.dark = vm.dark
    }

  },
  mounted () {
    // languagei18n
    if (localStorage.language) {
      this.language = localStorage.language;
    }
    else {
      const browserLocale = this.getBrowserLocale({ countryCodeOnly: true })
      if(['en', 'es'].includes(browserLocale)){
        this.language = browserLocale
      }
      else {
        this.language = 'en'
      }
    }

    // other watchers
    this.permissions = this.$store.getters.PERMISSIONS
    this.$store.watch(
      (state, getters) => getters.ROLES,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.roles = this.$store.getters.ROLES
      },
    )
    this.$store.watch(
      (state, getters) => getters.EXPIREDSUB,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.expiredSubscription = this.$store.getters.EXPIREDSUB
      })

    this.$store.watch(
      (state, getters) => getters.ACTIVE,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.status = newValue
        // if((oldValue === true || oldValue === false) && newValue !== null && this.roles.includes('owner')){
        // }
      },
    )
    this.$store.watch(
      (state, getters) => getters.STATUSV,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        if(this.loggedIn){
          this.$store.dispatch('GET_PAYMENT_INFO')
        }
      },
    )
    this.$store.watch(
      (state, getters) => getters.PLAN,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        if(this.loggedIn && newValue != null){
          this.$store.dispatch('GET_PERMISSIONS', newValue)
        }
      },
    )
    this.$store.watch(
      (state, getters) => getters.PERMISSIONS,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.permissions = newValue
      },
    )
    this.$store.watch(
      (state, getters) => getters.INCOMPLETE_USER,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.incompleteUser = newValue
      },
    )
    // this.$store.watch(
    //   (state, getters) => getters.EMAIL,
    //   // eslint-disable-next-line
    //   (newValue, oldValue) => {
    //     this.$store.dispatch('GET_CHAT_TOKEN')
    //   },
    // )
    // let vm = this
    // if(vm.$store.getters.HASORG === null){
    //   vm.getBasicInfo()
    // }
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  },
  beforeMount () {
    if(process.env.VUE_APP_WHITE_LABEL != null){
      const iconElements = document.getElementsByClassName("appIcon")
      console.log(iconElements)
      for(var key in iconElements){
        if(iconElements[key].href != null){
          var path = iconElements[key].href.split('/')
          path.splice(path.length-1, 0, process.env.VUE_APP_WHITE_LABEL)
          // console.log(path)
          iconElements[key].href = path.join('/')
        }
      }
    }
    if(process.env.VUE_APP_BTN_RADIUS != null){
      document.documentElement.style.setProperty('--btn-radius', process.env.VUE_APP_BTN_RADIUS)
    }
    
    this.loggedIn = false
    this.checkAuth()
    this.title = process.env.VUE_APP_TITLE
    // window.hsConversationsSettings = {
    //   loadImmediately: false
    // }
  },
  beforeDestroy() {
  },
};
</script>


<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}

:root {
  --btn-radius: 0.25rem;
}

.v-btn{
  border-radius:var(--btn-radius)!important;
} 
</style>
