import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import i18n from '@/plugins/i18n'
import '@babel/polyfill'
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import InstantSearch from 'vue-instantsearch';



Vue.use(VueReCaptcha, { siteKey: '6LennbwUAAAAAOqAqyKAdp5e2_OemyGOepre5qsc' })
Vue.use(Chartkick.use(Chart))
Vue.use(InstantSearch)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
