export default {
  state: {
    snackbar: {
      show: false,
      message: '',
      color: 'success', // success, error, info, warning
      timeout: 3000
    }
  },
  
  mutations: {
    SET_SNACKBAR(state, payload) {
      state.snackbar = {
        ...state.snackbar,
        ...payload
      }
    }
  },
  
  actions: {
    showSnackbar({ commit }, payload) {
      commit('SET_SNACKBAR', {
        show: true,
        ...payload
      })
    },
    
    showSuccess({ dispatch }, message) {
      dispatch('showSnackbar', {
        message,
        color: 'success',
        timeout: 3000
      })
    },
    
    showError({ dispatch }, message) {
      dispatch('showSnackbar', {
        message,
        color: 'error',
        timeout: 5000
      })
    },
    
    showInfo({ dispatch }, message) {
      dispatch('showSnackbar', {
        message,
        color: 'info',
        timeout: 3000
      })
    },
    
    hideSnackbar({ commit }) {
      commit('SET_SNACKBAR', {
        show: false
      })
    }
  },
  
  getters: {
    snackbar: state => state.snackbar
  }
} 