<template>
  <v-snackbar
    v-model="show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    :multi-line="message.length > 40"
    top
  >
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="hideSnackbar"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GlobalSnackbar',
  
  computed: {
    ...mapGetters(['snackbar']),
    show: {
      get() {
        return this.snackbar.show
      },
      set(value) {
        if (!value) {
          this.hideSnackbar()
        }
      }
    },
    message() {
      return this.snackbar.message || ''
    }
  },
  
  methods: {
    ...mapActions(['hideSnackbar'])
  }
}
</script> 