import Vue from 'vue'
import Vuex from 'vuex'
import {firebase, db} from '@/services/firebase'
import i18n from '@/plugins/i18n'
import notifications from './modules/notifications'
// var request = require("request")
var axios = require("axios")
// import ProcessesModule from './modules/processes'

Vue.use(Vuex)

const modules = {
  // processes: ProcessesModule
  notifications
}

function base64ToBuffer(str){
  str = window.atob(str); // creates a ASCII string
  var buffer = new ArrayBuffer(str.length),
      view = new Uint8Array(buffer);
  for(var i = 0; i < str.length; i++){
      view[i] = str.charCodeAt(i);
  }
  return buffer;
}

export default new Vuex.Store({
  state: {
    roles: null,
    paymentInfo: null,
    uid: null, 
    orgId: null,
    orgName: null,
    orgCountry: null,
    hasOrg: null,
    verified: null,
    token: null,
    ourTemplates: null,
    contractTemplates: null,
    saved:null,
    unassigned: null,
    reviewed: null,
    approved: null,
    approvedTeams: null,
    rejected: null,
    toreview: null,
    toReviewTeams: null,
    shared: null,
    firstName: null,
    lastName: null,
    email: null,
    active: null,
    statusV: null,
    statusP: null,
    updatingSubscription: null,
    disabledSubscription: null,
    expiredSubscription: null,
    lastRoute: null,
    dashToken: null,
    usersInOrg: null,
    users2email: null,
    projects2name: {},
    teams2name: {},
    projects: null,
    clients: null,
    clients2name: {},
    individual: null,
    global: null,
    lists: null,
    rejectionOptions: [],
    assignOnlyByManager: null,
    projectSelectionRequired: null,
    // watchers
    watchers: {},
    permissions: null,
    plan: null,
    planAnnual: false,
    orgTeams: null,
    teams: null,
    teamIds: [],
    alerts: null,
    alertsSent: null,
    incompleteUser: null,
    integrations: null,
    currencySymbols: {
      "GTQ": {
        "singular": "quetzal",
        "plural": "quetzales"
      },
      "USD": {
        "singular": "dólar de Estados Unidos de América",
        "plural": "dólares de Estados Unidos de América"
      },
    },
    logo: null,
    algoliaSearchKey: null,
  },
  getters: {
    UID : state => {
      return state.uid
    },
    TOKEN : state => {
      return state.token
    },
    ROLES : state => {
      return state.roles
    },
    PAYMENTINFO : state => {
      return state.paymentInfo
    },
    ORGID : state => {
      return state.orgId
    },
    ORGNAME : state => {
      return state.orgName
    },
    ORGCOUNTRY : state => {
      return state.orgCountry
    },
    HASORG : state => {
      return state.hasOrg
    },
    VERIFIED : state => {
      return state.verified
    },
    OURTEMPLATES : state => {
      return state.ourTemplates
    },
    CONTRACTTEMPLATES : state => {
      return state.contractTemplates
    },
    SAVED: state => {
      return state.saved
    },
    UNASSIGNED: state => {
      return state.unassigned
    },
    REVIEWED : state => {
      return state.reviewed
    },
    APPROVED : state => {
      return state.approved
    },
    APPROVED_TEAMS : state => {
      return state.approvedTeams
    },
    REJECTED : state => {
      return state.rejected
    },
    TOREVIEW : state => {
      return state.toreview
    },
    TO_REVIEW_TEAMS : state => {
      return state.toReviewTeams
    },
    SHARED : state => {
      return state.shared
    },
    FIRSTNAME : state => {
      return state.firstName
    },
    LASTNAME : state => {
      return state.lastName
    },
    EMAIL : state => {
      return state.email
    },
    ACTIVE : state => {
      return state.active
    },
    STATUSV : state => {
      return state.statusV
    },
    STATUSP : state => {
      return state.statusP
    },
    UPDATINGSUB : state => {
      return state.updatingSubscription
    },
    DISABLEDSUB : state => {
      return state.disabledSubscription
    },
    EXPIREDSUB : state => {
      return state.expiredSubscription
    },
    LASTROUTE : state => {
      return state.lastRoute
    },
    DASHTOKEN : state => {
      return state.dashToken
    },
    USERSINORG : state => {
      return state.usersInOrg
    },
    USERS2EMAIL : state => {
      return state.users2email
    },
    PROJECTS2NAME : state => {
      return state.projects2name
    },
    CLIENTS2NAME : state => {
      return state.clients2name
    },
    TEAMS2NAME : state => {
      return state.teams2name
    },
    PROJECTS: state => {
      return state.projects
    },
    CLIENTS: state => {
      return state.clients
    },
    WATCHERS: state => {
      return state.watchers
    },
    PERMISSIONS: state => {
      return state.permissions
    },
    PLAN: state => {
      return state.plan
    },
    PLAN_ANNUAL: state => {
      return state.planAnnual
    },
    INDIVIDUAL: state => {
      return state.individual
    },
    GLOBAL: state => {
      return state.global
    },
    ORG_TEAMS: state => {
      return state.orgTeams
    },
    TEAMS: state => {
      return state.teams
    },
    TEAM_IDS: state => {
      return state.teamIds
    },
    ALERTS: state => {
      return state.alerts
    },
    ALERTS_SENT: state => {
      return state.alertsSent
    },
    INCOMPLETE_USER: state => {
      return state.incompleteUser
    },
    INTEGRATIONS: state => {
      return state.integrations
    },
    LOGO: state => {
      return state.logo
    },
    LISTS: state => {
      return state.lists
    },
    REJECTIONOPTIONS: state => {
      return state.rejectionOptions
    },
    ASSIGNONLYBYMANAGER: state => {
      return state.assignOnlyByManager
    },
    PROJECTSELECTIONREQUIRED: state => {
      return state.projectSelectionRequired
    },
    ALGOLIASEARCHKEY: state => {
      return state.algoliaSearchKey
    }
  },
  mutations: {
    setuid: (state, payload) => {
      state.uid = payload
    },
    settoken: (state, payload) => {
      state.token = payload
    },
    setroles: (state, payload) => {
      state.roles = payload
    },
    setPaymentInfo: (state, payload) => {
      state.paymentInfo = payload
    },
    setorgId: (state, payload) => {
      state.orgId = payload
    },
    setorgName: (state, payload) => {
      state.orgName = payload
    },
    setorgCountry: (state, payload) => {
      state.orgCountry = payload
    },
    sethasOrg: (state, payload) => {
      state.hasOrg = payload
    },
    setverified: (state, payload) => {
      state.verified = payload
    },
    setOurTemplates: (state, payload) => {
      state.ourTemplates = payload
    },
    setcontractTemplates: (state, payload) => {
      state.contractTemplates = payload
    },
    setSavedProcesses: (state, payload) => {
      state.saved = payload
    },
    setUnassignedProcesses: (state, payload) => {
      state.unassigned = payload
    },
    setreviewed: (state, payload) => {
      state.reviewed = payload
    },
    setReviewedApproved: (state, payload) => {
      state.approved = payload
    },
    setReviewedApprovedTeams: (state, payload) => {
      state.approvedTeams = payload
    },
    setReviewedRejected: (state, payload) => {
      state.rejected = payload
    },
    settoreview: (state, payload) => {
      state.toreview = payload
    },
    setToReviewTeams: (state, payload) => {
      state.toReviewTeams = payload
    },
    setShared: (state, payload) => {
      state.shared = payload
    },
    setfirstname: (state, payload) => {
      state.firstName = payload
    },
    setlastname: (state, payload) => {
      state.lastName = payload
    },
    setemail: (state, payload) => {
      state.email = payload
    },
    setActive: (state, payload) => {
      state.active = payload
    },
    setStatusV: (state, payload) => {
      state.statusV = payload
    },
    setStatusP: (state, payload) => {
      state.statusP = payload
    },
    setUpdatingSubscription: (state, payload) => {
      state.updatingSubscription = payload
    },
    setDisabledSubscription: (state, payload) => {
      state.disabledSubscription = payload
    },
    setExpiredSubscription: (state, payload) => {
      state.expiredSubscription = payload
    },
    setLastRoute: (state, payload) => {
      state.lastRoute = payload
    },
    setDashToken: (state, payload) => {
      state.dashToken = payload
    },
    setUsersInOrg: (state, payload) => {
      var map = {}
      for(var user in payload){
        map[payload[user].uid] = payload[user].email
      }
      state.users2email = map
      state.usersInOrg = payload
    },
    setProjects: (state, payload) => {
      var map = {}
      for(var project in payload){
        map[payload[project].id] = payload[project].name
      }
      state.projects2name = map
      state.projects = payload
    },
    setClients: (state, payload) => {
      var map = {}
      for(var project in payload){
        map[payload[project].id] = payload[project].name
      }
      state.clients2name = map
      state.clients = payload
    },
    setWatcher: (state, payload) => {
      state.watchers[payload.name] = payload.watcher
    },
    delWatchers: (state) => {
      state.watchers = {}
    },
    setChatToken: (state, payload) => {
      state.chatToken = payload
    },
    setPermissions: (state, payload) => {
      state.permissions = payload
    },
    setPlan: (state, payload) => {
      state.plan = payload
    },
    setPlanAnnual: (state, payload) => {
      state.planAnnual = payload
    },
    setIndividual: (state, payload) => {
      state.individual = payload
    },
    setGlobal: (state, payload) => {
      state.global = payload
    },
    setOrgTeams: (state, payload) => {
      var map = {}
      for(var item in payload){
        map[payload[item].id] = payload[item].name
      }
      state.teams2name = map
      state.orgTeams = payload
    },
    setTeams: (state, payload) => {
      var map = {}
      for(var item in payload){
        map[payload[item].id] = payload[item].name
      }
      state.teams2name = map
      state.teams = payload
    },
    setTeamIds: (state, payload) => {
      state.teamIds = payload
    },
    setAlerts: (state, payload) => {
      state.alerts = payload
    },
    setAlertsSent: (state, payload) => {
      state.alertsSent = payload
    },
    setIncompleteUser: (state, payload) => {
      state.incompleteUser = payload
    },
    setIntegrations: (state, payload) => {
      state.integrations = payload
    },
    setLogo: (state, payload) => {
      state.logo = payload
    },
    setLists: (state, payload) => {
      state.lists = payload
    },
    setRejectionOptions: (state, payload) => {
      state.rejectionOptions = payload
    },
    setAssignOnlyByManager: (state, payload) => {
      state.assignOnlyByManager = payload
    },
    setProjectSelectionRequired: (state, payload) => {
      state.projectSelectionRequired = payload
    },
    setAlgoliaSearchKey: (state, payload) => {
      state.algoliaSearchKey = payload
    }
  },
  actions: {
    SET_ORGNAME: (context, orgName) => {
      context.commit('setorgName', orgName)
    },
    SET_LOGO: (context, logo) => {
      context.commit('setLogo', logo)
    },
    SAVE_VERIFIED: (context, verified) => {
      context.commit('setverified', verified)
    },
    SAVE_TOKEN: (context) => {
      return new Promise((resolve, reject) => {
        if(firebase.auth().currentUser){
          firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
            context.commit('settoken', idToken)
            resolve()
          })
        } else{
          reject()
        }
      })
    },
    GET_ORG_STATUS: (context, orgId) => {
      const status = db.collection('orgStatus').doc(orgId)
      // eslint-disable-next-line
      const obs = status.onSnapshot(doc => {
        var docData = doc.data()
        var active = docData['active']
        var statusV = docData['v']
        var statusP = docData['p']
        var updating = docData['updatingSubscription']
        var disabled = docData['disabledSubscription']
        var subscriptionExpired = docData['expiredSubscription']

        context.commit('setStatusV', statusV)
        context.commit('setStatusP', statusP)
        context.commit('setActive', active)
        context.commit('setUpdatingSubscription', updating)
        context.commit('setDisabledSubscription', disabled)
        context.commit('setExpiredSubscription', subscriptionExpired)
      })
      context.commit('setWatcher', {name: 'orgStatus', watcher: obs})
    },
    GET_PAYMENT_INFO: (context) => {
      if(context.getters.ROLES == null || !context.getters.ROLES.includes('owner')){
        return 
      }
      var data = JSON.stringify({
        uid: context.getters.UID,
        orgId: context.getters.ORGID,
        token: context.getters.TOKEN
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'org/info/payment',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      axios(config).then(function (response) {
        context.commit('setPaymentInfo', response.data.paymentInfo)
        if(response.data.plan != null){
          context.commit('setPlan', response.data.plan)
          context.commit('setPlanAnnual', response.data.planAnnual)
        }
        // eslint-disable-next-line
      }).catch(function (error) {
        return false
      })
    },
    RESET_BASIC_INFO: async (context) => {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        context.commit('settoreview', null)
        context.commit('setToReviewTeams', null)
        context.commit('setreviewed', null)
        context.commit('setReviewedApproved', null)
        context.commit('setReviewedApprovedTeams', null)
        context.commit('setReviewedRejected', null)
        context.commit('setcontractTemplates', null)
        context.commit('setOurTemplates', null)
        context.commit('setSavedProcesses', null)
        context.commit('sethasOrg', null)
        context.commit('setorgId', null)
        context.commit('setroles', null)
        context.commit('setPaymentInfo', null)
        context.commit('setuid', null)
        context.commit('setverified', null)
        context.commit('setActive', null)
        context.commit('setStatusV', null)
        context.commit('setStatusP', null)
        context.commit('setUpdatingSubscription', null)
        context.commit('setDisabledSubscription', null)
        context.commit('setExpiredSubscription', null)
        context.commit('setDashToken', null)
        context.commit('setUsersInOrg', null)
        context.commit('setProjects', null)
        context.commit('setChatToken', null)
        context.commit('setPermissions', null)
        context.commit('setPlan', null)
        context.commit('setPlanAnnual', false)
        context.commit('setIndividual', null)
        context.commit('setGlobal', null)
        context.commit('setOrgTeams', null)
        context.commit('setTeams', null)
        context.commit('setTeamIds', [])
        context.commit('setAlerts', null)
        context.commit('setAlertsSent', null)
        context.commit('setfirstname', null)
        context.commit('setlastname', null)
        context.commit('setorgName', null)
        context.commit('setorgCountry', null)
        context.commit('setIntegrations', null)
        context.commit('setLogo', null)
        context.commit('setAlgoliaSearchKey', null)
        resolve()
      })
    },
    GET_BASIC_INFO: async (context) => {
      return new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser
        if(user) {
          context.commit('setuid', user.uid)
          context.commit('setverified', user.emailVerified)
          context.commit('setemail', user.email)
          db.collection('users').doc(user.uid).get().then(function (doc) {
            var data = doc.data()
            if(doc.exists && data.orgId){
              
              context.commit('setfirstname', data.firstName)
              context.commit('setlastname', data.lastName)
              context.commit('setemail', data.email)
              
              var obj = JSON.stringify({
                uid: user.uid,
                orgId: data.orgId,
                token: context.getters.TOKEN
              })
              var config = {
                method: 'POST',
                url: process.env.VUE_APP_ENGINE+'getRolesUser',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: obj
              }
              axios(config).then(async function (response) {
                if(response.data.roles && response.data.roles != []) {
                  if(response.data.plan != null){
                    // var permissions = await db.collection('plans').doc(body.plan).get()
                    // permissions = permissions.data()
                    context.commit('setPlanAnnual', response.data.planAnnual)
                    context.commit('setPlan', response.data.plan)
                    // context.commit('setPermissions', permissions)
                  }
                  if(response.data.integrations != null){
                    context.commit('setIntegrations', response.data.integrations)
                  }
                  if(response.data.searchKey){
                    context.commit('setAlgoliaSearchKey', response.data.searchKey)
                  }
                  context.commit('sethasOrg', true)
                  context.commit('setorgId', data.orgId)
                  context.commit('setorgName', data.orgName)
                  context.commit('setorgCountry', response.data.country)
                  context.commit('setroles', response.data.roles)
                  context.commit('setTeams', response.data.teams)
                  context.commit('setTeamIds', response.data.teamIds)
                  context.commit('setIndividual', response.data.individual)
                  context.commit('setGlobal', response.data.global)
                  context.commit('setRejectionOptions', response.data.rejectionOptions)
                  context.commit('setAssignOnlyByManager', response.data.assignOnlyByManager)
                  context.commit('setProjectSelectionRequired', response.data.projectSelectionRequired)
                  if(response.data.customTheme != null){
                    localStorage.customTheme = JSON.stringify(response.data.customTheme)
                  }
                  if(response.data.customLogo != null){
                    context.commit('setLogo', "data:"+response.data.customLogo.mime+";base64," +response.data.customLogo.file )
                  }
                  // context.commit('setPaymentInfo', body.paymentInfo)
                  context.dispatch('GET_ORG_STATUS', data.orgId)
                  resolve()
                }
                else{
                  context.commit('sethasOrg', false)
                  resolve()
                  alert('No roles')
                }
                // eslint-disable-next-line
              }).catch(function (error) {
                reject()
                throw new Error(error)
              })
            }
            else if(doc.exists && data.orgId == null){
              context.commit('sethasOrg', false)
              context.commit('setfirstname', data.firstName)
              context.commit('setlastname', data.lastName)
              resolve()
            }
            else {
              console.log('incomplete User')
              context.commit('sethasOrg', false)
              context.commit('setIncompleteUser', true)
              resolve()
            }
          })
        }
        else{
          reject()
        }
      })
    },
    GET_OUR_TEMPLATES: (context, country) => {
      return new Promise((resolve, reject) => {
        var templatesWatcher = db.collection('ourTemplates').where('country', '==', country).where('deleted', '==', false).onSnapshot(function(querySnapshot) {
          var templates = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timeCreated = data.timeCreated.toDate().toLocaleDateString('en-GB')
            templates.push(data)
          })
          try {
            context.commit('setOurTemplates', templates)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'ourTemplates', watcher: templatesWatcher})
      })
    },
    GET_CONTRACT_TEMPLATES: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var templatesWatcher = db.collection('organizations').doc(context.getters.ORGID).collection('templates').where('deleted', '==', false).onSnapshot(function(querySnapshot) {
          var contr = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timeCreated = data.timeCreated.toDate().toLocaleDateString('en-GB')
            contr.push(data)
          })
          try {

            context.commit('setcontractTemplates', contr)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'templates', watcher: templatesWatcher})
      })
    },
    GET_SAVED_PROCESSES: (context) => {
      if(context.getters.ORGID == null || context.getters.UID == null) return
      return new Promise((resolve, reject) => {
        var savedWatcher = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('uid', '==', context.getters.UID).where('deleted', '==', false).where('saved', '==', true).where('reviewed', '==', false).where('approved', '==', false).where('rejected', '==', false).where('status', '==', 'drafting').orderBy('timestamp', 'desc').onSnapshot(function(querySnapshot) {
          var saved = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.saveTimestamp = data.saveTimestamp.toDate().toLocaleDateString('en-GB')
            saved.push(data)
          })
          try {
            context.commit('setSavedProcesses', saved)
            resolve()
          } catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'savedProcesses', watcher: savedWatcher})
      })
    },
    GET_UNASSIGNED_PROCESSES: (context) => {
      if(context.getters.ORGID == null || context.getters.UID == null) return
      return new Promise((resolve, reject) => {
        var watcher = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('status', '==', 'unassigned').orderBy('timestamp', 'asc').onSnapshot(function(querySnapshot) {
          var processes = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            if(data.unassignedTimestamp != null){
              data.unassignedTimestamp = data.unassignedTimestamp.toDate().toLocaleDateString('en-GB')
            }
            processes.push(data)
          })
          try {
            context.commit('setUnassignedProcesses', processes)
            resolve()
          } catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'savedProcesses', watcher: watcher})
      })
    },
    GET_REVIEWED_PROCESSES_APPROVED: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        let approvedRef
        if(context.getters.ROLES.includes('owner')){
          approvedRef = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', true).where('rejected', '==', false).where('status', '==', 'approved').orderBy('timestamp', 'desc')
        }
        else if(context.getters.INDIVIDUAL === true){
          approvedRef = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('uid', '==', context.getters.UID).where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', true).where('rejected', '==', false).where('status', '==', 'approved').where('teams', '==', null).orderBy('timestamp', 'desc')
        }
        else if(context.getters.INDIVIDUAL === false){
          approvedRef = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', true).where('rejected', '==', false).where('status', '==', 'approved').where('teams', '==', null).orderBy('timestamp', 'desc')
        }
        else {
          reject()
        }
        var reviewedWatcher = approvedRef.onSnapshot(function(querySnapshot) {
          var reviews = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.reviewTimestampLetters = data.reviewTimestamp.toDate().toLocaleDateString('en-GB')
            data.creatorEmail = context.getters.USERS2EMAIL[data.uid]
            data.reviewerEmail = context.getters.USERS2EMAIL[data.reviewer]
            data.projectName = context.getters.PROJECTS2NAME[data.project]
            data.teamNames = ''
            if(data.teams != null){
              var names = []
              for(var team in data.teams){
                if(context.getters.TEAMS2NAME[data.teams[team]] != null){
                  names.push(context.getters.TEAMS2NAME[data.teams[team]])
                }
              }
              data.teamNames = names.join(', ')
            }
            reviews.push(data)
          })
          try {
            context.commit('setReviewedApproved', reviews)
            resolve()
          } catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'reviewedProcessesApproved', watcher: reviewedWatcher})
      })
    },
    GET_REVIEWED_PROCESSES_APPROVED_TEAMS: (context) => {
      if(context.getters.ORGID == null || context.getters.TEAM_IDS.length == 0 || context.getters.ROLES.includes('owner')) return
      return new Promise((resolve, reject) => {
        let approvedRef
        if(context.getters.INDIVIDUAL === true){
          approvedRef = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('uid', '==', context.getters.UID).where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', true).where('rejected', '==', false).where('status', '==', 'approved').where('teams', 'array-contains-any', context.getters.TEAM_IDS).orderBy('timestamp', 'desc')
        }
        else if(context.getters.INDIVIDUAL === false){
          approvedRef = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', true).where('rejected', '==', false).where('status', '==', 'approved').where('teams', 'array-contains-any', context.getters.TEAM_IDS).orderBy('timestamp', 'desc')
        }
        else {
          reject()
        }
        var reviewedWatcher = approvedRef.onSnapshot(function(querySnapshot) {
          var reviews = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.reviewTimestampLetters = data.reviewTimestamp.toDate().toLocaleDateString('en-GB')
            data.creatorEmail = context.getters.USERS2EMAIL[data.uid]
            data.reviewerEmail = context.getters.USERS2EMAIL[data.reviewer]
            data.projectName = context.getters.PROJECTS2NAME[data.project]
            data.teamNames = ''
            if(data.teams != null){
              var names = []
              for(var team in data.teams){
                if(context.getters.TEAMS2NAME[data.teams[team]] != null){
                  names.push(context.getters.TEAMS2NAME[data.teams[team]])
                }
              }
              data.teamNames = names.join(', ')
            }
            reviews.push(data)
          })
          try {
            context.commit('setReviewedApprovedTeams', reviews)
            resolve()
          } catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'reviewedProcessesApprovedTeams', watcher: reviewedWatcher})
      })
    },
    GET_REVIEWED_PROCESSES_REJECTED: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var reviewedWatcher = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', true).where('approved', '==', false).where('rejected', '==', true).where('status', '==', 'rejected').where('uid', '==', context.getters.UID).orderBy('timestamp', 'desc').onSnapshot(function(querySnapshot) {
          var reviews = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.reviewTimestamp = data.reviewTimestamp.toDate().toLocaleDateString('en-GB')
            reviews.push(data)
          })
          try {
            context.commit('setReviewedRejected', reviews)
            resolve()
          } catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'reviewedProcessesRejected', watcher: reviewedWatcher})
      })
    },

    GET_TO_REVIEW_PROCESSES: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        // all processes
        var ref = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', false).where('approved', '==', false).where('rejected', '==', false).where('status', '==', 'reviewing').where('teams', '==', null).orderBy('timestamp')
        if(context.getters.ROLES.includes('owner')){
          ref = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', false).where('approved', '==', false).where('rejected', '==', false).where('status', '==', 'reviewing').orderBy('timestamp')
        }
        var toReviewWatcher = ref.onSnapshot(function(querySnapshot) {
          var reviews = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.timestampLetters = data.timestamp.toDate().toLocaleDateString('en-GB')
            reviews.push(data)
          })
          try {
            context.commit('settoreview', reviews)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'toReview', watcher: toReviewWatcher})
      })
    },
    GET_TO_REVIEW_PROCESSES_TEAMS: (context) => {
      if(context.getters.ORGID == null || context.getters.TEAM_IDS.length == 0) return
      return new Promise((resolve, reject) => {
        var toReviewWatcher = db.collection('organizations').doc(context.getters.ORGID).collection('processes').where('deleted', '==', false).where('saved', '==', false).where('reviewed', '==', false).where('approved', '==', false).where('rejected', '==', false).where('status', '==', 'reviewing').where('teams', 'array-contains-any', context.getters.TEAM_IDS).orderBy('timestamp').onSnapshot(function(querySnapshot) {
          var reviews = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.id = doc.id
            data.timestampLetters = data.timestamp.toDate().toLocaleDateString('en-GB')
            reviews.push(data)
          })
          try {
            context.commit('setToReviewTeams', reviews)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'toReviewTeams', watcher: toReviewWatcher})
      })
    },
    GET_SHARED: (context) => {
      return new Promise((resolve, reject) => {
        var sharedWatcher = db.collectionGroup('shared').where('email', '==', firebase.auth().currentUser.email).where('deleted', '==', false).where('completed', '==', false).orderBy('timeCreated').onSnapshot(function(querySnapshot) {
          var shared = []
          querySnapshot.forEach(function (doc) {
            var data = doc.data()
            data.timeCreated = data.timeCreated.toDate().toLocaleDateString('en-GB')
            shared.push(data)
          })
          try {
            context.commit('setShared', shared)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'shared', watcher: sharedWatcher})
      })
    },
    GET_DASH_TOKEN: (context) => {
      return new Promise((resolve, reject) => {
        var data = JSON.stringify({
          uid: context.getters.UID,
          orgId: context.getters.ORGID,
          token: context.getters.TOKEN
        })
        var config = {
          method: 'POST',
          url: process.env.VUE_APP_ENGINE+'dash/token',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }
        axios(config).then(function (response) {
          const jwt = response.data.jwt
          context.commit('setDashToken', jwt)
          resolve()
          // eslint-disable-next-line
        }).catch(function (error) {
          reject()
        })
      })
    },
    UPDATE_USERS_ROLES: (context) => {
      if(context.getters.USERSINORG == null) return
      var users = context.getters.USERSINORG
      function translateRoles(role){
        return i18n.t('myOrg.'+role)
      }
      users.forEach(user => {
        var roles = user.roles.map(translateRoles).join(', ')
        user.sroles = roles
      })
      context.commit('setUsersInOrg', users)
    },
    GET_USERS_IN_ORG: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var ref = db.collection('organizations').doc(context.getters.ORGID).collection('roles').where('deleted', '==', false)
        var usersWatcher = ref.onSnapshot(function (querySnapshot) {
          var users = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.uid = doc.id
            data.useremail = doc.email
            function translateRoles(role){
              return i18n.t('myOrg.'+role)
            }
            var roles = data.roles.map(translateRoles).join(', ')
            data.sroles = roles
            if(data.roles.includes('owner') || data['acceptedInvite'] != null && data['acceptedInvite'] == true){
              data.acceptedInvite = "Yes"
            }
            else{
              data.acceptedInvite = "No"
            }
            users.push(data)
          })
          try{
            context.commit('setUsersInOrg', users)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'users', watcher: usersWatcher})
      })
    },
    GET_PROJECTS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var ref = db.collection('organizations').doc(context.getters.ORGID).collection('projects').where('deleted', '==', false).orderBy('timestamp', 'desc')
        var projectsWatcher = ref.onSnapshot(function (querySnapshot) {
          var projects = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timestamp = data.timestamp.toDate().toLocaleDateString('en-GB')
            projects.push(data)
          })
          try{
            context.commit('setProjects', projects)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'projects', watcher: projectsWatcher})
      })
    },
    GET_CLIENTS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var ref = db.collection('organizations').doc(context.getters.ORGID).collection('clients').where('deleted', '==', false).orderBy('timestamp', 'desc')
        var watcher = ref.onSnapshot(function (querySnapshot) {
          var items = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timestamp = data.timestamp.toDate().toLocaleDateString('en-GB')
            items.push(data)
          })
          try{
            context.commit('setClients', items)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'projects', watcher: watcher})
      })
    },
    GET_CHAT_TOKEN: (context) => {
      // return new Promise((resolve, reject) => {
      if(context.getters.UID == null || context.getters.EMAIL == null || context.getters.TOKEN == null) return
      var obj = {
        uid:  context.getters.UID,
        email: context.getters.EMAIL,
        token: context.getters.TOKEN
      }

      var data = JSON.stringify(obj)
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'chat/token',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        const token = 'asfdsaa'
        context.commit('setChatToken', token)
        window.hsConversationsSettings = {
          identificationEmail: context.getters.EMAIL,
          identificationToken: token
        }
        window.HubSpotConversations.widget.load()
        // eslint-disable-next-line
      }).catch(function (error) {
        return false
      })
    },
    GET_PERMISSIONS: async (context, plan) => {
      if(context.getters.ORGID == null) return
      var permissions = await db.collection('plans').doc(plan).get()
      permissions = permissions.data()
      context.commit('setPermissions', permissions)
    },
    GET_ORG_TEAMS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        var ref = db.collection('organizations').doc(context.getters.ORGID).collection('teams').where('deleted', '==', false).orderBy('timestamp', 'desc')
        var teamWatcher = ref.onSnapshot(function (querySnapshot) {
          var teams = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timestamp = data.timestamp.toDate().toLocaleDateString('en-GB')
            teams.push(data)
          })
          try{
            context.commit('setOrgTeams', teams)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'orgTeams', watcher: teamWatcher})
      })
    },
    GET_ALERTS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        const orgRef = db.collection('organizations').doc(context.getters.ORGID)
        var ref = orgRef.collection('alerts').where('sent', '==', false).where('deleted', '==', false).orderBy('timeAlert', 'asc')
        var alertWatcher = ref.onSnapshot(function (querySnapshot) {
          var alerts = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timeAlertS = data.timeAlert.toDate().toLocaleDateString('en-GB')
            data.timeCreatedS = data.timeCreated.toDate().toLocaleDateString('en-GB')
            alerts.push(data)
          })
          try{
            context.commit('setAlerts', alerts)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'orgAlerts', watcher: alertWatcher})
      })
    },
    GET_SENT_ALERTS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        const orgRef = db.collection('organizations').doc(context.getters.ORGID)
        var ref = orgRef.collection('alerts').where('sent', '==', true).where('deleted', '==', false).orderBy('timeAlert', 'desc').limit(100)
        var alertWatcher = ref.onSnapshot(function (querySnapshot) {
          var alerts = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.timeAlertS = data.timeAlert.toDate().toLocaleDateString('en-GB')
            data.timeCreatedS = data.timeCreated.toDate().toLocaleDateString('en-GB')
            alerts.push(data)
          })
          try{
            context.commit('setAlertsSent', alerts)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'orgAlertsSent', watcher: alertWatcher})
      })
    },
    GET_LISTS: (context) => {
      if(context.getters.ORGID == null) return
      return new Promise((resolve, reject) => {
        const orgRef = db.collection('organizations').doc(context.getters.ORGID)
        var ref = orgRef.collection('lists').where('deleted', '==', false)
        var listsWatcher = ref.onSnapshot(function (querySnapshot) {
          var items = []
          querySnapshot.forEach(function (doc) {
            const data = doc.data()
            data.id = doc.id
            data.createDate = data.createTimestamp.toDate().toLocaleDateString('en-GB')
            data.lastModified = data.timestamp.toDate().toLocaleDateString('en-GB')
            data.items.forEach(it=> {
              it.id = it[data.identifier]
            })
            items.push(data)
          })
          try{
            context.commit('setLists', items)
            resolve()
          }
          catch {
            reject()
          }
        })
        context.commit('setWatcher', {name: 'lists', watcher: listsWatcher})
      })
    },
    RESET_EVERYTHING: (context) => {
      return new Promise(async (resolve, reject) => {
        try {
          // console.log(context.getters.WATCHERS)
          for(var key in context.getters.WATCHERS){
            await context.getters.WATCHERS[key]()
          }
          context.commit('settoreview', null)
          context.commit('setToReviewTeams', null)
          context.commit('setreviewed', null)
          context.commit('setReviewedApproved', null)
          context.commit('setReviewedApprovedTeams', null)
          context.commit('setReviewedRejected', null)
          context.commit('setcontractTemplates', null)
          context.commit('setOurTemplates', null)
          context.commit('setSavedProcesses', null)
          context.commit('setUnassignedProcesses', null)
          context.commit('sethasOrg', null)
          context.commit('setorgId', null)
          context.commit('setroles', null)
          context.commit('setPaymentInfo', null)
          context.commit('settoken', null)
          context.commit('setuid', null)
          context.commit('setverified', null)
          context.commit('setActive', null)
          context.commit('setStatusV', null)
          context.commit('setStatusP', null)
          context.commit('setUpdatingSubscription', null)
          context.commit('setDisabledSubscription', null)
          context.commit('setExpiredSubscription', null)
          context.commit('setDashToken', null)
          context.commit('setUsersInOrg', null)
          context.commit('setProjects', null)
          context.commit('setClients', null)
          context.commit('setChatToken', null)
          context.commit('setemail', null)
          context.commit('setPermissions', null)
          context.commit('setPlan', null)
          context.commit('setPlanAnnual', false)
          context.commit('setIndividual', null)
          context.commit('setGlobal', null)
          context.commit('setOrgTeams', null)
          context.commit('setTeams', null)
          context.commit('setTeamIds', null)
          context.commit('setAlerts', null)
          context.commit('setAlertsSent', null)
          context.commit('setfirstname', null)
          context.commit('setlastname', null)
          context.commit('setorgName', null)
          context.commit('setorgCountry', null)
          context.commit('setIncompleteUser', null)
          context.commit('setLogo', null)
          context.commit('setLists', null)
          context.commit('delWatchers')
          resolve()
        } catch {
          context.commit('settoreview', null)
          context.commit('setToReviewTeams', null)
          context.commit('setreviewed', null)
          context.commit('setReviewedApproved', null)
          context.commit('setReviewedApprovedTeams', null)
          context.commit('setReviewedRejected', null)
          context.commit('setcontractTemplates', null)
          context.commit('setOurTemplates', null)
          context.commit('setSavedProcesses', null)
          context.commit('setUnassignedProcesses', null)
          context.commit('sethasOrg', null)
          context.commit('setorgId', null)
          context.commit('setroles', null)
          context.commit('setPaymentInfo', null)
          context.commit('settoken', null)
          context.commit('setuid', null)
          context.commit('setverified', null)
          context.commit('setActive', null)
          context.commit('setStatusV', null)
          context.commit('setStatusP', null)
          context.commit('setUpdatingSubscription', null)
          context.commit('setDisabledSubscription', null)
          context.commit('setExpiredSubscription', null)
          context.commit('setDashToken', null)
          context.commit('setUsersInOrg', null)
          context.commit('setProjects', null)
          context.commit('setClients', null)
          context.commit('setChatToken', null)
          context.commit('setemail', null)
          context.commit('setPermissions', null)
          context.commit('setPlan', null)
          context.commit('setPlanAnnual', false)
          context.commit('setIndividual', null)
          context.commit('setGlobal', null)
          context.commit('setOrgTeams', null)
          context.commit('setTeams', null)
          context.commit('setTeamIds', null)
          context.commit('setAlerts', null)
          context.commit('setAlertsSent', null)
          context.commit('setfirstname', null)
          context.commit('setlastname', null)
          context.commit('setorgName', null)
          context.commit('setorgCountry', null)
          context.commit('setIntegrations', null)
          context.commit('setIncompleteUser', null)
          context.commit('setLogo', null)
          context.commit('setLists', null)
          context.commit('setAlgoliaSearchKey', null)
          context.commit('delWatchers')
          reject()
        }
      })
    }
  },
  modules
})
